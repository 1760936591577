import {IconButton, styled, Tooltip} from "@mui/material";
import {grey} from "@mui/material/colors";
import {Link, useLocation} from "react-router-dom";
import {createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Outer = styled("div")<{selected: boolean, disabled?: boolean}>(props => ({
    cursor: "pointer",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    overflow: "hidden",
    marginRight: 10,
    minWidth: 180,
    opacity: props.disabled ? 0.6 : undefined,
    backgroundColor: props.disabled ? grey["100"] : props.selected ? grey["300"] : "",
    border: "1px solid " + (props.selected ? grey["300"] : "transparent"),
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderLeft: "none",
    position: "relative",

    "&:hover": {
        backgroundColor: grey["200"],
    }
}));

const ChildMatchContext = createContext({
    renderId: "",
    isMatch: (match: boolean, path: string, renderId: string) => {},
})

const prevChildMatched = {} as {[path: string]: boolean};
const prevExpanded = {} as {[path: string]: boolean};

export function SidebarItem(props: PropsWithChildren<{
    icon: any;
    name: string;
    link: string;
    indent?: number
    disabled?: boolean;
    noMatchChildren?: boolean;
    forceSelected?: boolean;
}>) {
    const [expanded, setExpanded] = useState<boolean>(prevExpanded[props.link] || false);
    const [childMatches, setChildMatches] = useState<boolean>(prevChildMatched[props.link] || false);

    // prevent flicker
    prevExpanded[props.link] = expanded;
    prevChildMatched[props.link] = childMatches;

    const loc = useLocation();
    const selected = (() => {
        if(!props.link) return false;
        if(props.noMatchChildren) return loc.pathname === props.link;
        return loc.pathname.startsWith(props.link);
    })();

    const ctx = useContext(ChildMatchContext);
    ctx.isMatch(selected, props.link, ctx.renderId); // notify parent of match

    const hasExpander = !!props.children;
    const hasLink = !!props.link;

    useEffect(() => {
        setExpanded(selected || childMatches);
    }, [selected, childMatches, hasExpander]);

    const renderId = Date.now().toString();
    const matchesRef = useRef({} as {[path: string]: boolean});
    matchesRef.current = {};

    const [checkOnChildMatches, setCheckOnChildMatches] = useState(false);

    // auto reset childMatches when child a is no longer selected after 700ms
    useEffect(() => {
        if(!checkOnChildMatches) return;

        const timeout = setTimeout(() => {

            // check if one got enabled in the meantime (race condition)
            for(let k in matchesRef.current) {
                if(matchesRef.current[k] === true) return;
            }

            setChildMatches(false);
            setCheckOnChildMatches(false);
        }, 700);

        return () => clearTimeout(timeout);
    }, [checkOnChildMatches, props.name]);

    return (
        <>
            <Link to={props.disabled ? "" : props.link} style={{color: "inherit", textDecoration: "none"}}>
                <Outer selected={selected} disabled={props.disabled} onClick={(e) => {
                    if(hasExpander && !hasLink) {
                        e.preventDefault()
                        e.stopPropagation()
                        setExpanded(!expanded);
                    }
                }}>
                    <Tooltip title={props.name || ""} placement="right">
                        <div style={{display: "flex", paddingLeft: props.indent ? props.indent * 18 : undefined}}>
                            <div style={{paddingRight: 16, overflow: "hidden", fontSize: "1.3rem", height: "1em"}}>
                                {props.icon}
                            </div>
                            <div style={{maxWidth: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                                {props.name}
                            </div>

                            {hasExpander ? <>
                                <div style={{width: 16}} />
                                <IconButton size="small" style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }} onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExpanded(!expanded);
                                }}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </>: null}
                        </div>
                    </Tooltip>
                </Outer>
            </Link>
            <div style={{height: expanded ? "auto" : 0, overflow: "hidden"}}>
                <ChildMatchContext.Provider value={{
                    renderId: renderId,
                    isMatch: (match: boolean, path: string, childRenderId: string) => {
                        if(childRenderId !== renderId) {
                            console.log("old render", childRenderId, renderId)
                            return; // old render
                        }

                        matchesRef.current[path] = match;

                        if(match) {
                            // trigger matches early
                            setChildMatches(true);
                            setCheckOnChildMatches(false);
                            return;
                        }

                        for(let k in matchesRef.current) {
                            if(matchesRef.current[k] === true) {
                                return;
                            }
                        }

                        if(childMatches) {
                            // no matches
                            setCheckOnChildMatches(true);
                        }
                    }
                }}>
                    {props.children}
                </ChildMatchContext.Provider>
            </div>
        </>
    );
}