import {Paginated} from "./Users";
import {SubAPI} from "./SubAPI";
import {FileInfo} from "./Files"
import {DateString} from "./Projects";

export type RelatedFileType = "custom-preps"

export class Products extends SubAPI {
    list(input: {
        productCode?: string;
        name?: string;
        manufacturer?: number;
        category?: number;
        offset?: number;
        forProductSettings?: boolean
    }) {
        return this.fetcher.get<Paginated<Product>>("/api/products", input)
    }

    relatedFiles(input: {
        product: number;
    }) {
        return this.fetcher.get<FileInfo[]>("/api/products/related-files", input)
    }

    search(input:
        ({ productSearch: string; } | { productCodeSearch: string; }) &
        {
            id?: number;
            preferMfg?: number;
            preferFinish?: string;
            category?: number;
            kind?: "hardware" | "div-10" | "hardware,div-10" | "frame-anchor"|"manufacture";
            offset?: number;
        }) {
        return this.fetcher.get<Paginated<ProductSearchResult>>("/api/products/search", input)
    }

    searchSignal(input: {
        product: number;
        searchQuery: string;
    }) {
        return this.fetcher.post("/api/products/search-signal", input)
    }

    categories(input: {
        offset?: number;
        kind?: "div-10" | "hardware"
        parent?: string
    }) {
        return this.fetcher.get<Paginated<Category>>("/api/product/categories", input)
    }

    upsert(input: {
        id?: number;
        productCode: string;
        manufacturer: number;
        name: string;
        finish: string;
        category: number;
        catalogPages: string;
        defaultQty: number;
        defaultProvidedByOthers: boolean;

        updateQuotePricingForProject?: number
    }) {
        return this.fetcher.post<Product>("/api/product", input)
    }

    upload(props: {
        file: File;
        id: number;
    }) {
        return this.fetcher.postFormData<{id: number}>("/api/div-10/set-cut-sheet", {
            file: props.file,
            info: JSON.stringify({id: props.id}),
        });
    }

    setAutoDimensionRules(props: {
        product: number;

        length: string;
        roundLength: string;
        roundTypeLength: RoundType | null;

        width: string;
        roundWidth: string;
        roundTypeWidth: RoundType | null;

        height: string;
        roundHeight: string;
        roundTypeHeight: RoundType | null;
    }) {
        return this.fetcher.post("/api/product/auto-dimension-rules", props)
    }

    listById(input: { ids: number[] }) {
        return this.fetcher.post<Product[]>("/api/products", input)
    }

    getFinishNameForCode(input: { code: string }) {
        return this.fetcher.get<{finishName: string}>("/api/product/finish-name", input)
    }

    buildDoorSpec(input: {
        manufacturer: number;
        width: string;
        height: string;
        thickness: string;
        finish: string;
        material: string;
        series: string;
        gauge: string;
        elevation: string;
        core: string;
        seam: string;
        handing: string;
        label: string;
    }) {
        return this.fetcher.post<Product>("/api/product/door-spec", input)
    }

    listMeta(input: {
        product: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<ProductMetaLine>>("/api/product-meta/list", input)
    }
}

export type ProductMetaLine = {
    id: number;
    description: string;
    product: number;
    createdAt: DateString
    createdBy: number;
    createdByName: string;
}

export type Category = {
    id: number;
    name: string;
    sortOrder: number | null;
    parentCategory?: number;
    kind: "hardware" | "div-10"
}

export type Product = {
    id: number;
    parentProduct: number;

    hideFromSearch: boolean;
    isStocking: boolean;

    name: string;
    productCode: string;
    finish: string;
    finishName: string;

    defaultQty: number;
    defaultProvidedByOthers: boolean;

    partOfFrame: boolean;

    category: number;
    categoryName: string;
    kind: "hardware" | "div-10" | "frame-anchor";

    manufacturer: number;
    manufacturerName: string;
    supplier: number;
    supplierName: string;
    defaultDistributor: number | null;
    defaultDistributorName: string | null;

    defaultHwGroup: OpeningType2;

    hwPrepKey: string;

    hwPrepSingleFrame: string;
    hwPrepSingleFrameCustomTemplate?: number;
    hwPrepSingleFrameCustomTemplateName?: string;
    hwPrepSingleActiveDoor: string;
    hwPrepSingleActiveDoorCustomTemplate?: number;
    hwPrepSingleActiveDoorCustomTemplateName?: string;
    hwPrepPairFrame: string;
    hwPrepPairFrameCustomTemplate?: number;
    hwPrepPairFrameCustomTemplateName?: string;
    hwPrepPairActiveDoor: string;
    hwPrepPairActiveDoorCustomTemplate?: number;
    hwPrepPairActiveDoorCustomTemplateName?: string;
    hwPrepPairInactiveDoor: string;
    hwPrepPairInactiveDoorCustomTemplate?: number;
    hwPrepPairInactiveDoorCustomTemplateName?: string;
    hwPrepPairBothActiveDoor: string;
    hwPrepPairBothActiveDoorCustomTemplate?: number;
    hwPrepPairBothActiveDoorCustomTemplateName?: string;

    keyed: boolean;

    frameSpec?: number;
    doorSpec?: number;
    div10Spec?: number;

    dimLengthFormula?: string;
    dimLengthRoundType?: RoundType;
    dimLengthRoundInterval?: string;

    dimWidthFormula?: string;
    dimWidthRoundType?: RoundType;
    dimWidthRoundInterval?: string;

    dimHeightFormula?: string;
    dimHeightRoundType?: RoundType;
    dimHeightRoundInterval?: string;

    dimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;

    catalog?: number;
    catalogName?: string;
    catalogPages: string;

    isAutoQtyHinge: boolean;

    defaultCostCents?: number;

    door: DoorSpec | null;

    updatedAt: DateString;
    updatedBy?: number;
    updatedByName?: string;
}

export type DoorSpec = {
    core: string
    elevation: string;
    elevationFile?: number
    finish: string
    gauge: string
    handing: string;
    height: string;
    label: string;
    material: string;
    seam: string;
    series: string
    thickness: string
    width: string
}

export type RoundType = "round-up-by-list-interval" | "round-up-by-fixed-interval" | "none";

export type OpeningType = "active" | "inactive" | "common";
export type OpeningType2 = OpeningType | "each-door";

export type ProductSearchResult = Product & {
    lastUnitCostCents: number;
}


export function fixFinishName(name: string | undefined | null) {
    if(name === "N/A") return "";
    return name;
}